<template>
  <div>
    <hero-global title="Canal Audiovisual"
                 img="/img/audiovisual-channel/audiovisual-channel-1.png"
                 first-width="75"
                 last-width="25"/>
    <div class="container p-x-2-rem">
      <div class="grid-2 align-items-center">
        <div class="grid">
          <h2>Últimas publicaciones</h2>
          <span class="underline m-left-22"></span>
        </div>
        <div class="grid">
          <div class="d-flex justify-content-end align-items-center">
            <div class="w-50 p-right-2-rem">
              <div class="text-right">
                <i class="icon icon-grid h-30-px w-30-px m-x-10-px"></i>
                <i class="icon icon-list h-30-px w-30-px m-x-10-px"></i>
                <i class="icon icon-search h-30-px w-30-px m-x-10-px"></i>
              </div>
            </div>
            <div class="w-50 d-flex background-blue-3 p-10-px align-items-center">
              <div class="w-50">
                <p class="font-family-frank-ruhl">Ordernar por: </p>
              </div>
              <div
                  class="border-radius-30-px background-white w-50 m-x-10-px p-6-px d-flex justify-content-between align-items-center">
                <span class="font-family-frank-ruhl text-bold font-s">Fecha:</span>
                <i class="icon icon-select h-24-px w-24-px"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-3">
        <card-new img="/img/img_2.png" v-for="(data, index) in dataInfo" :key="index"
                  width="33"
                  :schedule="''"
                  :title="''"
                  :date="data.date">
          <template v-slot:subtitle>
            <span>
                  Grabación audiovisual accesible del XVI Congreso de CERMIS Autonómicos. Valladolid 18 de febrero de 2022
            </span>
          </template>
        </card-new>
      </div>
      <br/>
      <br/>
      <pagination-global/>
      <br/>
      <br/>
      <section class="p-2-rem">
        <other-links-interest class="p-1-rem shadow"/>
      </section>
      <br/>
    </div>

  </div>
</template>

<script>
import HeroGlobal from "@/components/Global/HeroGlobal";
import CardNew from "@/components/Card/CardNew";
import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
import PaginationGlobal from "@/components/Global/PaginationGlobal";

export default {
  name: "AudiovisualChannel",
  components: {PaginationGlobal, OtherLinksInterest, CardNew, HeroGlobal},
  data:()=>({
    dataInfo:[]
  }),
  mounted() {
    for (let i = 0; i < 9; i++) {
      this.dataInfo = [...this.dataInfo, {date: '04 marzo 2022'}]
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/icons";

</style>
